import * as React from "react"
import { Link } from "gatsby"
import { MdArrowForward } from "react-icons/md"
import { ThemeCss } from "gatsby-interface"

const asideCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.magenta[5],
  display: `grid`,
  paddingLeft: theme.space[7],
  paddingRight: theme.space[7],
  maxWidth: `740px`,

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `1fr 5fr`,
  },
})

const imageContainerCss: ThemeCss = theme => ({
  display: `none`,
  [theme.mediaQueries.tablet]: {
    display: `block`,
  },
})

const contentContainerCss: ThemeCss = theme => ({
  paddingTop: theme.space[8],
  paddingBottom: theme.space[8],
})

const ctaContainerCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  [theme.mediaQueries.tablet]: {
    flexDirection: `row`,
  },
})
interface CalloutCtaProps {
  to: string
  text: string
}

interface CalloutProps {
  iconSrc?: string
  ctas?: CalloutCtaProps[]
  children: React.ReactNode
}

const CalloutCta = ({ to, text }: CalloutCtaProps) => {
  return (
    <div css={theme => ({ marginRight: theme.space[7] })}>
      <Link to={to}>
        {text}{" "}
        <MdArrowForward
          css={theme => ({
            fontSize: theme.fontSizes[1],
            marginBottom: `-.05rem`,
          })}
        />
      </Link>
    </div>
  )
}

const Callout = ({ iconSrc, ctas, children }: CalloutProps) => {
  return (
    <aside css={asideCss}>
      <div css={imageContainerCss}>
        <img css={{ margin: 0 }} src={iconSrc} alt="" />
      </div>
      <div css={contentContainerCss}>
        {children}
        <div css={ctaContainerCss}>
          {ctas
            ? ctas.map(({ to, text }) => (
                <CalloutCta key={to} to={to} text={text} />
              ))
            : null}
        </div>
      </div>
    </aside>
  )
}

export default Callout
