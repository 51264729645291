/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { get } from "lodash-es"
import { showcaseItem } from "templates/shared/styles"
import EmptyGridItems from "templates/shared/empty-grid-items"
import { StarterCard } from "./starter-card"
import SmallCard from "./small-card"
// imports to allow the category starters page tobe reused
import { Heading } from "gatsby-interface"
import React from "react"
const acceptedCategories = [
  "Official",
  "E-commerce",
  "Documentation",
  "Blog",
  "Portfolio",
]

const hasSearchTerm = urlState =>
  urlState.search !== "" ||
  urlState.type.length > 0 ||
  urlState.cms.length > 0 ||
  urlState.feature.length > 0

const EmptyStartersList = ({ emptyStateReason }) => (
  <div
    css={{
      display: `grid`,
      height: `80%`,
      alignItems: `center`,
      justifyContent: `center`,
      textAlign: `center`,
    }}
  >
    <h1>
      No {emptyStateReason} starters found!
      <small
        sx={{
          color: `text.secondary`,
          display: `block`,
          fontWeight: `body`,
          mt: 3,
        }}
      >
        Why not write one and
        {` `}
        <Link to="/contributing/submit-to-starter-library/">submit it</Link>? Or
        learn more
        {` `}
        <Link to="/docs/starters">about starters</Link>.
      </small>
    </h1>
  </div>
)

const StartersList = ({ urlState, starters, count }) => {
  if (!starters.length) {
    // empty state!
    const emptyStateReason =
      urlState.search !== ``
        ? urlState.search // if theres a search term
        : `matching` // if no search term
    return <EmptyStartersList emptyStateReason={emptyStateReason} />
  }

  if (!count) {
    return null
  }

  // when there is no search term, return the category based starters
  if (!hasSearchTerm(urlState)) {
    starters = starters.sort(sortByGitHubStars)
    return <CategoryStarters starters={starters} />
  }

  starters = starters.sort(sortByGitHubStars).slice(0, count)
  return (
    <div
      sx={{
        display: `grid`,
        gridTemplateColumns: [
          `1fr`,
          null,
          null,
          `1fr 1fr`,
          null,
          `repeat(auto-fill, minmax(min(512px, 100%), 1fr));`,
        ],
        gap: 7,
        justifyItems: `center`,
        pt: 5,
        px: [0, 3, 5],
      }}
    >
      {starters.map(starter => {
        return <StarterCard key={starter.id} starter={starter} size="M" />
      })}
      {starters.length && <EmptyGridItems styles={showcaseItem} />}
    </div>
  )
}

/*
 Takes unorganized starters and places them in categories
 returns an object where each key is a category and values are an array of starters
 (note: only uses the categories mentioned above - acceptedCategories)
*/

const MAX_NUMBER_OF_STARTERS_PER_CATEGORY = 8
export const placeStartersInCategories = starters => {
  return starters.reduce((allCategoryStarters, currentStarter) => {
    // eslint-disable-next-line no-unused-expressions
    currentStarter.starterTags.nodes?.forEach(({ name: tag }) => {
      if (acceptedCategories.includes(tag)) {
        if (!allCategoryStarters[tag]) allCategoryStarters[tag] = []
        if (
          allCategoryStarters[tag].length < MAX_NUMBER_OF_STARTERS_PER_CATEGORY
        )
          allCategoryStarters[tag].push(currentStarter)
      }
    })
    return allCategoryStarters
  }, {})
}

/*
  takes sorted starters and places them in an object
  starters from the accepted categories are sorted in the order of highest GH stars
  Note: Starters may show up in multiple categories due to tags
*/
export const CategoryStarters = ({ starters }) => {
  const categoryStarters = React.useMemo(
    () => placeStartersInCategories(starters),
    [starters]
  )

  return (
    <div
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        flexDirection: `column`,
        justifyItems: `flex-start`,
        alignItems: `stretch`,
        pt: 5,
        px: [0, 3, 5],
      }}
    >
      {acceptedCategories.map(category => {
        return (
          categoryStarters[category]?.length > 0 && (
            <div key={category}>
              <Heading
                as="h3"
                variant="DEFAULT"
                css={theme => ({
                  width: `100%`,
                  textAlign: "center",
                  paddingBottom: theme.space[8],
                  [theme.mediaQueries.mobile]: {
                    width: `360px`,
                    textAlign: "center",
                  },
                  [theme.mediaQueries.phablet]: {
                    width: `100%`,
                  },
                  [theme.mediaQueries.tablet]: {
                    textAlign: "left",
                  },
                })}
              >
                {category}
              </Heading>
              <ul
                css={{
                  display: `flex`,
                  flexDirection: `row`,
                  flexWrap: `wrap`,
                }}
              >
                {categoryStarters[category]?.map(starter => {
                  return (
                    <SmallCard key={starter.id + category} starter={starter} />
                  )
                })}
              </ul>
            </div>
          )
        )
      })}

      {starters.length && <EmptyGridItems styles={showcaseItem} />}
    </div>
  )
}

export default StartersList

function sortByGitHubStars(nodeA, nodeB) {
  const metricA = get(nodeA, `githubMetaFields.stars`, 0)
  const metricB = get(nodeB, `githubMetaFields.stars`, 0)
  return metricB - metricA
}
