import * as React from "react"
import { Link } from "gatsby"
import { Heading, Text } from "gatsby-interface"
import Gatsby4Icon from "assets/icons/gatsby-4-icon.svg"
import Callout from "components/callout"

const HEADER_TITLE = "Gatsby Starter Library"

const headerLinks = [
  {
    text: "Starter documentation",
    url: "/docs/how-to/local-development/starters/",
  },
]

const sectionCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  width: `100%`,
  minWidth: `360px`,
  gap: theme.space[7],
  padding: `${theme.space[5]} ${theme.space[7]}`,
})

const headingsCss = theme => ({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: "flex-end",
  gap: theme.space[7],
  margin: "0 0",
})

const paragraphCss = theme => ({
  maxWidth: `740px`,
  fontWeight: theme.fontWeights.body,
  margin: `${theme.space[5]} ${theme.space[0]}`,
  fontSize: theme.fontSizes[2],
  fontFamily: theme.fonts.body,
  lineHeight: theme.lineHeights.body,
  color: theme.colors.grey[90],
})

const calloutData = {
  iconSrc: Gatsby4Icon,
  ctas: [
    { to: `/gatsby-4`, text: `Gatsby 4 overview` },
    { to: `/gatsby-4/starters`, text: `See Gatsby 4 Beta Starters` },
  ],
}

const StarterHeader = () => {
  return (
    <section css={sectionCss}>
      <div>
        <div css={headingsCss}>
          <Heading
            as="h1"
            css={_ => ({
              paddingBottom: `0px`,
              marginBottom: `0px`,
            })}
          >
            {HEADER_TITLE}
          </Heading>
          {headerLinks.map(link => {
            return (
              <Link
                key={`${link.text}-${link.url}`}
                to={link.url}
                variant={"DEFAULT"}
                css={_ => ({
                  textDecoration: "underline",
                })}
              >
                {link.text}
              </Link>
            )
          })}
        </div>
        <p css={paragraphCss}>
          Starters are open-source Gatsby sites maintained by Gatsby and the
          Gatsby community. Ranging from minimal boilerplate to full
          proofs-of-concept, Starters enable you to dive into your next Gatsby
          project—<em>fast</em>.
        </p>
      </div>
      <Callout iconSrc={calloutData.iconSrc} ctas={calloutData.ctas}>
        <Text css={theme => ({ marginBottom: theme.space[4] })}>
          <strong css={theme => ({ color: theme.colors.grey[90] })}>
            Gatsby 4 beta is here
          </strong>{" "}
          and available in select Starters.
        </Text>
      </Callout>
    </section>
  )
}

export default StarterHeader

/*

*/
