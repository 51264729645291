import { SplitLink as Link } from "components/links/SplitLink"
import { Badge, Heading, Text } from "gatsby-interface"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { MdStar } from "react-icons/md"

const StarterCategory = ({ starter, ...props }) => {
  const { category } = starter

  return category ? (
    <Badge size="S" tone="NEUTRAL" {...props}>
      {category}
    </Badge>
  ) : null
}

const StarterImage = ({ starter }) => {
  const screenshot = getImage(starter.starterFields?.screenshot?.localFile)

  return (
    <div
      css={theme => ({
        borderRadius: `${theme.radii[4]} ${theme.radii[4]} 0 0`,
        position: `relative`,
      })}
    >
      <GatsbyImage
        image={screenshot}
        alt={`Screenshot of ${starter.title}`}
        width={`100%`}
        imgStyle={{
          borderBottom: `1px solid #78757A`,
        }}
        css={theme => ({
          borderRadius: `${theme.radii[4]} ${theme.radii[4]} 0 0`,
        })}
      />
      <StarterCategory
        css={theme => ({
          position: `absolute`,
          top: theme.space[5],
          right: theme.space[8],
          boxShadow: theme.shadows.raised,
        })}
        starter={starter}
      />
    </div>
  )
}

const makeVisible = fieldIsAvailable => ({
  visibility: fieldIsAvailable ? `visible` : `hidden`,
})

const GithubMajorVersion = ({ starter }) => {
  const [versionInfo] = starter?.githubMetaFields?.gatsbyMajorVersion
  const text = versionInfo?.length > 0 ? `Gatsby v${versionInfo[1]}` : ` `
  return (
    <div
      css={theme => ({
        height: `24px`,
        width: `80px`,
        textAlign: `center`,
        color: theme.colors.grey[50],
        fontSize: theme.fontSizes[0],
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows.raised,
        borderRadius: theme.radii[2],
        border: `1px solid ${theme.colors.white}`,
        ...makeVisible(versionInfo?.length > 0),
      })}
    >
      {text}
    </div>
  )
}
const GithubStars = ({ starter }) => {
  const { stars } = starter.githubMetaFields
  return stars ? (
    <div
      aria-label={`${stars} stars`}
      css={theme => ({
        display: `flex`,
        alignItems: `center`,
        gap: theme.space[2],
        color: theme.colors.grey[50],
        fontSize: theme.fontSizes[2],
      })}
    >
      <MdStar /> {stars}
    </div>
  ) : null
}

const githubFieldsAvailable = starter => {
  const majorVersion = starter?.githubMetaFields?.gatsbyMajorVersion
  let versionInfo
  if (majorVersion?.length > 0) {
    versionInfo = majorVersion[0]
  }

  return versionInfo?.length > 0 || starter?.githubMetaFields?.stars
}
/*
retrieve the properties from github, if unavailable, revert to wp for these properties

*/
export default function SmallCard({ starter }) {
  const cardRef = React.useRef()
  let description = starter.githubMetaFields?.description
  let title = starter?.githubMetaFields?.name

  if (!description) {
    description = starter?.starterFields?.description.split(". ")[0]
  }
  if (!title) {
    title = starter.title?.split("/")[1]
  }

  React.useEffect(() => {
    let down
    let up
    const link = cardRef.current.querySelector("a")

    cardRef.current.onmousedown = () => (down = +new Date())
    cardRef.current.onmouseup = () => {
      up = +new Date()
      if (up - down < 200) {
        link.click()
      }
    }
  }, [])

  return (
    <li
      ref={cardRef}
      css={theme => ({
        cursor: `pointer`,
        listStyle: `none`,
        borderRadius: theme.radii[4],
        boxSizing: `border-box`,
        boxShadow: theme.shadows.raised,
        marginRight: theme.space[4],
        width: `292px`,
        minWidth: `200px`,
        "&:hover": {
          textDecoration: `none`,
          borderRadius: theme.radii[4],
          transform: `translateY(-${theme.space[2]})`,
          boxShadow: theme.shadows.overlay,
        },
        "&:focus-within": {
          textDecoration: `none`,
          borderRadius: theme.radii[4],
          transform: `translateY(-${theme.space[2]})`,
          boxShadow: theme.shadows.overlay,
        },
        [theme.mediaQueries.tablet]: {
          width: `315px`,
        },
        [theme.mediaQueries.desktop]: {
          width: `350px`,
        },
      })}
    >
      <div
        css={theme => ({
          display: `grid`,
          textDecoration: `none`,
          alignItems: `stretch`,
          transition: theme.transitions.default,
          minWidth: `200px`,
          width: `100%`,
          height: `100%`,
        })}
      >
        <StarterImage starter={starter} />
        <div
          css={theme => ({
            padding: `${theme.space[6]} ${theme.space[7]}`,
          })}
        >
          <Link
            css={{
              textDecoration: `none`,
              "&:hover": {
                textDecoration: `none`,
              },
              "&:focus-within": {
                textDecoration: `none`,
              },
            }}
            to={`/starters${starter.githubMetaFields.slug}`}
          >
            <Heading as="h4">{title}</Heading>
          </Link>
          <Text
            variant="LEDE"
            css={theme => ({
              height: theme.space[15],
              maxHeight: theme.space[15],
              display: `block`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
              marginBottom: theme.space[5],
              fontSize: theme.fontSizes[2],
              lineHeight: theme.lineHeights.body,
            })}
          >
            {description}
          </Text>
          {githubFieldsAvailable(starter) ? (
            <div
              css={_ => ({
                display: `flex`,
                justifyContent: `space-between`,
              })}
            >
              <GithubMajorVersion starter={starter} />
              <GithubStars starter={starter} />
            </div>
          ) : null}
        </div>
      </div>
    </li>
  )
}
