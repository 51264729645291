import React, { Component } from "react"
import queryString from "query-string"
import FilteredStarters from "./filtered-starters"
import SEO from "components/seo"

const emptySearchState = {
  search: ``,
  cms: [],
  type: [],
  feature: [],
  sort: `recent`,
}
class StarterLibraryPage extends Component {
  state = emptySearchState

  setUrlState = newState => {
    const finalState = { ...this.state, ...newState }
    this.setState({ ...finalState })
  }

  render() {
    const { location } = this.props
    const urlState = queryString.parse(location.search)

    const filtersApplied =
      urlState.search !== undefined
        ? urlState.search // if theres a search term
        : `Library` // if no search term

    return (
      <>
        <SEO
          title={`Gatsby Starters: ${filtersApplied}`}
          description={`Get up and running with pre-made Gatsby templates!`}
          shouldIndex={true}
        />

        <FilteredStarters
          {...this.props}
          setURLState={this.setUrlState}
          urlState={this.state}
        />
      </>
    )
  }
}

export default StarterLibraryPage
