/** @jsx jsx */
import { jsx } from "theme-ui"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"
import { filterButton, filterCheckbox } from "../../../templates/shared/styles"
import { Heading } from "gatsby-interface"

const buttonStyles = isSelected => ({
  ...filterButton,
  color: isSelected ? `link.color` : `text.primary`,
})
export default function LHSFilter({
  heading,
  data,
  filters,
  setFilters,
  allowMultiple = true,
}) {
  return (
    <div sx={{ py: 5 }}>
      <Heading as="h4" sx={{ mb: 5 }}>
        {heading.toUpperCase()}
      </Heading>
      <ul sx={{ m: 0, listStyle: `none` }}>
        {data.map(tag => {
          const isSelected = filters.has(tag)
          return (
            <li key={tag} sx={{ m: 0 }}>
              <button
                className={isSelected ? `selected` : ``}
                onClick={() => {
                  if (isSelected) {
                    filters.delete(tag)
                    setFilters(filters)
                  } else {
                    setFilters(allowMultiple ? filters.add(tag) : [tag])
                  }
                }}
                sx={buttonStyles(isSelected)}
              >
                {allowMultiple ? (
                  <div sx={filterCheckbox}>
                    {isSelected ? (
                      <MdCheckBox style={{ verticalAlign: `-0.125em` }} />
                    ) : (
                      <MdCheckBoxOutlineBlank
                        style={{ verticalAlign: `-0.125em` }}
                      />
                    )}
                  </div>
                ) : null}
                <div sx={{ mr: `auto` }}>{tag}</div>
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

LHSFilter.defaultProps = {
  fixed: false,
}
