import React from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/core"
import Header from "components/header"
import Layout from "../../layouts"
import { SkipNavTarget } from "components/shared/components/SkipNav"
import StarterLibrary from "components/starters/library/index"

const StarterPage = ({ data: { allWpStarter }, location }) => {
  return (
    <Layout>
      <Global
        styles={{
          body: {
            overflow: `visible`,
          },
          a: {
            textDecoration: `none`,
          },
        }}
      />
      <Header isFullWidth />
      <main>
        <SkipNavTarget />
        <StarterLibrary data={allWpStarter} location={location} />
      </main>
    </Layout>
  )
}

export default StarterPage

export const pageQuery = graphql`
  query StarterPageQuery {
    allWpStarter(
      filter: {
        starterFields: {
          screenshot: { localFile: { childImageSharp: { id: { ne: null } } } }
        }
      }
    ) {
      nodes {
        ...StarterLibraryFields
      }
    }
  }

  fragment StarterLibraryFields on WpStarter {
    id
    title
    starterFields {
      description
      features
      repoUrl
      url
      screenshot {
        id
        localFile {
          childImageSharp {
            id
            gatsbyImageData(layout: CONSTRAINED, width: 512, aspectRatio: 2)
          }
        }
      }
    }
    starterTags {
      nodes {
        id
        name
      }
    }
    githubMetaFields {
      slug
      stub
      name
      description
      stars
      lastUpdated
      owner
      githubFullName
      gatsbyMajorVersion
      allDependencies
      gatsbyDependencies
      miscDependencies
    }
  }
`
