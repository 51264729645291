import * as React from "react"
import { Text, Badge } from "gatsby-interface"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MdStar } from "react-icons/md"
import { SplitLink as Link } from "../../../components/links/SplitLink"

const StarterImage = ({ starter }) => {
  const screenshot = getImage(starter.starterFields?.screenshot?.localFile)

  return (
    <div css={{ position: `relative` }}>
      <GatsbyImage
        image={screenshot}
        alt={`Screenshot of ${starter.title}`}
        css={imageCss}
      />
      <StarterCategory
        css={theme => ({
          position: `absolute`,
          top: theme.space[5],
          right: theme.space[8],
          boxShadow: theme.shadows.raised,
        })}
        starter={starter}
      />
    </div>
  )
}

const GithubStars = ({ starter }) => {
  const { stars } = starter.githubMetaFields
  return stars ? (
    <div css={starsCss}>
      <MdStar /> {stars}
    </div>
  ) : null
}

const StarterCategory = ({ starter, ...props }) => {
  const { category } = starter

  return category ? (
    <Badge size="S" tone="NEUTRAL" {...props}>
      {category}
    </Badge>
  ) : null
}

const StarterName = ({ starter }) => {
  return (
    <div css={{ width: `fit-content` }}>
      <Text
        as="div"
        variant={"EMPHASIZED"}
        css={theme => ({ color: theme.colors.grey[90] })}
      >
        {/* the title is in format repo/starter-name, so we split out the starter-name */}
        {starter.githubMetaFields.name || starter.title.split("/")[1]}
      </Text>
    </div>
  )
}

const linkCss = {
  textDecoration: `none`,
  "&:hover": {
    textDecoration: `none`,
  },
}

const cardSizeCss = (theme, size) => {
  switch (size) {
    case "L":
      return {
        [theme.mediaQueries.phablet]: {
          width: `327px`,
        },
        [theme.mediaQueries.tablet]: {
          width: `512px`,
        },
      }
    default:
      return {
        [theme.mediaQueries.phablet]: {
          width: `315px`,
        },
        [theme.mediaQueries.tablet]: {
          width: `350px`,
        },
        [theme.mediaQueries.hd]: {
          width: `512px`,
        },
      }
  }
}

const cardCss = (theme, size) => [
  {
    borderRadius: theme.radii[4],
    boxShadow: theme.shadows.raised,
    display: `grid`,
    transition: theme.transitions.default,
    width: `290px`,

    "&:hover": {
      transform: `translateY(-${theme.space[2]})`,
      boxShadow: theme.shadows.overlay,
    },
  },
  cardSizeCss(theme, size),
]

const infoRowCss = theme => ({
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  display: `flex`,
  justifyContent: `space-between`,
  padding: theme.space[5],
})

const imageCss = theme => ({
  borderRadius: `${theme.radii[4]} ${theme.radii[4]} 0 0`,
})

const starsCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  gap: theme.space[2],
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[2],
})

export function StarterCard({ starter, size = "M" }) {
  return (
    <Link css={linkCss} to={`/starters${starter.githubMetaFields.slug}`}>
      <div css={theme => cardCss(theme, size)}>
        <StarterImage starter={starter} />
        <div css={infoRowCss}>
          <StarterName starter={starter} />
          <GithubStars starter={starter} />
        </div>
      </div>
    </Link>
  )
}
