import * as React from "react"
import { Heading } from "gatsby-interface"
import { StarterCard } from "./starter-card"

const sectionCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
  padding: `${theme.space[5]} ${theme.space[7]}`,
})

const listWrapperCss = {
  position: `relative`,
  overflowX: `scroll`,
  scrollbarWidth: `none` /* Firefox */,
  "ms-overflow-style": `none` /* Internet Explorer 10+ */,
  "&::-webkit-scrollbar": {
    /* WebKit */
    width: 0,
    height: 0,
  },
}

const gradientOverlayCss = {
  background: `linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))`,
  bottom: 7,
  pointerEvents: `none`,
  position: `fixed`,
  right: -7,
  top: 0,
  width: 60,
}

const listCss = theme => ({
  display: `flex`,
  gap: theme.space[7],
  listStyle: `none`,
  margin: 0,
})

const GradientOverlay = () => <div css={gradientOverlayCss} />

const recommendedList = [
  ["gatsbyjs/gatsby-starter-shopify", "E-Commerce"],
  ["LekoArts/gatsby-starter-portfolio-cara", "Portfolio"],
  ["Vagr9K/gatsby-advanced-starter", "Boilerplate"],
  ["kosvrouvas/gatsby-tailwindcss-starter", "Tailwind"],
  ["contentful/starter-gatsby-blog", "Contentful"],
  ["TryGhost/gatsby-starter-ghost", "Ghost"],
  ["hasura/gatsby-gitbook-starter", "Documentation"],
  ["henrikwirth/gatsby-starter-wordpress-twenty-twenty", "Wordpress"],
  ["jpedroschmitz/gatsby-starter-ts", "Typescript"],
  ["gatsbyjs/gatsby-starter-blog", "Blog"],
  ["LekoArts/gatsby-starter-portfolio-jodie", "Portfolio"],
  ["gatsbyjs/gatsby-starter-hello-world", "Boilerplate"],
]

const RecommendedStarters = ({ data }) => {
  const { nodes: starters } = data
  const filteredStarters = React.useMemo(() => {
    return recommendedList.reduce((acc, [title, category]) => {
      const starter = starters.find(s => s.title === title)
      if (starter) {
        starter.category = category
        acc.push(starter)
      }
      return acc
    }, [])
  }, [recommendedList, starters])

  return (
    <section css={sectionCss}>
      <Heading as="h2">Recommended</Heading>
      <div css={listWrapperCss}>
        <ul css={listCss}>
          {filteredStarters.map(starter => {
            return (
              <li key={starter.id}>
                <StarterCard starter={starter} size="L" />
              </li>
            )
          })}
        </ul>
        <GradientOverlay />
      </div>
    </section>
  )
}

export default RecommendedStarters
