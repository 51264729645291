/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { MdArrowDownward } from "react-icons/md"
import { Button } from "gatsby-interface"
import LHSFilter from "./lhs-filter"
import StarterList from "./starter-list"
import DebounceInput from "./DebounceInput"
import { themedInput } from "../../../utils/styles/org"
import { loadMoreButton } from "../../../templates/shared/styles"
import {
  SidebarBody,
  SidebarContainer,
  ContentHeader,
  ContentContainer,
} from "../../../templates/shared/sidebar"
import ResetFilters from "../../../templates/shared/reset-filters"
import FooterLinks from "../../shared/footer-links"
import StarterHeader from "./starter-header"
import RecommendedStarters from "./recommended-starters"

const typeOptions = [
  "Official",
  "E-commerce",
  "Documentation",
  "Blog",
  "Portfolio",
]
const cmsOptions = [
  "Contentful",
  "DatoCMS",
  "Ghost",
  "Netlify CMS",
  "Prismic",
  "Strapi",
  "Sanity",
  "Shopify",
  "WordPress",
]

const excludeFromLibrary = ["v4"]

const DEFAULT_SITES_TO_SHOW = 12

/*
Returns an array of starter tags that do not belong in the Type or CMS category:
 
1. Map each starter to its starter tags
2. Filter out those tags if they are in the CMS or Type categories
3. Flatten the nested array
4. De-dupe the flattened array using Set
5. Sort the array, case-insensitive
*/
const getFeatureOptions = starterNodes => {
  const typesOrCMS = [...typeOptions, ...cmsOptions]

  return [
    ...new Set(
      starterNodes.flatMap(starter => {
        return starter.starterTags.nodes
          .map(tag => {
            if (!typesOrCMS.includes(tag.name)) {
              return tag.name
            }
            return null
          })
          .filter(tag => Boolean(tag))
      })
    ),
  ].sort((a, b) => a.localeCompare(b))
}

export default function FilteredStarterLibrary({
  data,
  urlState,
  setURLState,
}) {
  const [sitesToShow, setSitesToShow] = React.useState(DEFAULT_SITES_TO_SHOW)

  /*
  Create callbacks for setting filter state
*/
  const setFiltersCms = filtersCms => {
    setURLState({ cms: Array.from(filtersCms) })
  }

  const setFiltersType = filtersType =>
    setURLState({ type: Array.from(filtersType) })

  const setFiltersFeatures = filtersFeatures =>
    setURLState({ feature: Array.from(filtersFeatures) })

  const resetFilters = () =>
    setURLState({ cms: [], type: [], feature: [], search: `` })

  const onChangeUrlWithText = value => setURLState({ search: value })

  const showMoreSites = starters => {
    const shouldShowAll = sitesToShow + 15 > starters.length
    setSitesToShow(prevCount =>
      shouldShowAll ? starters.length : prevCount + 15
    )
  }
  /*
  Create set of currently active filters
  */
  const filtersCms = new Set(
    Array.isArray(urlState.cms) ? urlState.cms : [urlState.cms]
  )
  const filtersType = new Set(
    Array.isArray(urlState.type) ? urlState.type : [urlState.type]
  )
  const filtersFeatures = new Set(
    Array.isArray(urlState.feature) ? urlState.feature : [urlState.feature]
  )

  // Merge filters into single set https://stackoverflow.com/a/32001444
  const filters = new Set([...filtersCms, ...filtersType, ...filtersFeatures])

  const isUrlStateEmpty =
    urlState.search === "" &&
    urlState.type.length === 0 &&
    urlState.cms.length === 0 &&
    urlState.feature.length === 0

  // stopgap for missing gh data (#8763)
  let starterNodes = data.nodes.filter(starter => starter.githubMetaFields)

  /*
  Filter starters array by active filters
  */

  if (urlState.search.length > 0) {
    starterNodes = starterNodes.filter(node =>
      JSON.stringify(node)
        .toLowerCase()
        .includes(urlState.search.toLowerCase())
    )
  }

  if (excludeFromLibrary.length > 0) {
    starterNodes = filterBySelections(starterNodes, excludeFromLibrary, {
      exclude: true,
    })
  }
  if (filtersCms.size > 0) {
    starterNodes = filterBySelections(starterNodes, filtersCms)
  }
  if (filtersType.size > 0) {
    starterNodes = filterBySelections(starterNodes, filtersType)
  }
  if (filtersFeatures.size > 0) {
    starterNodes = filterBySelections(starterNodes, filtersFeatures)
  }

  /*
  Returns the set of available features to filter on, based on the 
  current set of filtered starters
  */
  const featureOptions = getFeatureOptions(starterNodes)

  const isMoreSitesToShow = sitesToShow < starterNodes.length

  return (
    <React.Fragment>
      <StarterHeader />
      <RecommendedStarters data={data} />
      <section className="showcase" css={{ display: `flex` }}>
        <SidebarContainer css={{ overflowY: `auto` }}>
          <SidebarBody>
            {filters.size > 0 || urlState.search.length > 0 ? (
              <div sx={{ height: t => t.space[11] }}>
                <ResetFilters onClick={resetFilters} />
              </div>
            ) : null}
            <LHSFilter
              heading="Type"
              data={typeOptions}
              filters={filtersType}
              setFilters={setFiltersType}
              allowMultiple={false}
            />
            <LHSFilter
              heading="CMS"
              data={cmsOptions}
              filters={filtersCms}
              setFilters={setFiltersCms}
              allowMultiple={false}
            />
            {featureOptions.length > 0 ? (
              <LHSFilter
                heading="Features and Technologies"
                data={featureOptions}
                filters={filtersFeatures}
                setFilters={setFiltersFeatures}
              />
            ) : null}
          </SidebarBody>
        </SidebarContainer>
        <ContentContainer>
          <ContentHeader>
            <div
              sx={theme => ({
                display: `flex`,
                justifyContent: `space-between`,
                mb: 3,
                width: `100%`,
                [theme.mediaQueries.phablet]: {
                  justifyContent: `flex-end`,
                },
              })}
            >
              <DebounceInput
                type="text"
                sx={theme => ({
                  ...themedInput(theme),
                  display: `flex`,
                  justifyContent: `space-between`,
                  mb: 3,
                  paddingLeft: theme.space[8],
                  width: `100%`,
                  [theme.mediaQueries.phablet]: {
                    justifyContent: `flex-end`,
                  },
                })}
                value={urlState.search}
                onChange={onChangeUrlWithText}
                placeholder={`Search ${starterNodes.length} Starters`}
                aria-label={`Search ${starterNodes.length} Starters`}
              />
            </div>
          </ContentHeader>
          <StarterList
            urlState={urlState}
            sortRecent={urlState.sort === `recent`}
            starters={starterNodes}
            count={sitesToShow}
          />
          {isMoreSitesToShow && !isUrlStateEmpty ? (
            <Button
              size="L"
              sx={loadMoreButton}
              onClick={() => showMoreSites(starterNodes)}
              rightIcon={<MdArrowDownward />}
            >
              Load More
            </Button>
          ) : null}
          <FooterLinks />
        </ContentContainer>
      </section>
    </React.Fragment>
  )
}

// utility functions

function filterBySelections(nodes, filters, options = { exclude: false }) {
  const { exclude } = options
  return nodes.filter(node =>
    exclude
      ? !isSuperset(
          node.starterTags.nodes.map(tag => tag.name),
          filters
        )
      : isSuperset(
          node.starterTags.nodes.map(tag => tag.name),
          filters
        )
  )
}

function isSuperset(set, subset) {
  for (const elem of subset) {
    if (!set.includes(elem)) {
      return false
    }
  }
  return true
}
